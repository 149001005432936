import request from '@/utils/request'

// 查询借阅记录列表
export function listLendRecord(data) {
  return request({
    url: '/lendRecord/get',
    method: 'post', 
    data
  })
}

// 根据乐兔码 查询图书
export function FindLendRecord(params){
  return request({
    url:"/lendRecord/find",
    method:'get',
    params
  })
}

 
// 添加借阅记录
export function addLendRecord(data) {
  return request({
    url: '/lendRecord/add',
    method: 'post',
    data
  })
}

// 修改借阅记录
export function updateLendRecord(data) {
  return request({
    url: '/lendRecord/update',
    method: 'post',
    data
  })
}

// 删除借阅记录
export function deleteLendRecord(params) {
  return request({
    url: '/lendRecord/delete',
    method: 'get',
    params
  })
}

// 统计借阅归还数据
export function countLendRecord(params){
  return request({
    url:'/lendRecord/count',
    method:'get',
    params
  })
}

// 统计图书借阅排行榜
export function countLendRecordRank(params){
  return request({
    url:'/lendRecord/bookCount',
    method:'get',
    params
  })
}

// 统计读者数据
export function countLendRecordReader(params){
  return request({
    url:'/lendRecord/readerCount',
    method:'get',
    params
  })
}

// 统计读者排行榜
export function countLendRecordReaderRank(params){
  return request({
    url:'/lendRecord/readerRank',
    method:'get',
    params
  })
}

//导出借阅记录
export function exportLendExcel(data){
  return request({
    url: "/lendRecord/export",
    method: "post",
    responseType: "blob",
    data
  })
}

//当前分管下的借阅规则
export function getLendRecord(){
  return request({
    url: "/lendRule/findBranch",
    method: "get",
  })  
}